<template>
  <div>
    <v-app-bar hide-on-scroll color="background" flat app height="64">
      <span class="app-bar--container">
        <!-- <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon> -->
        <v-toolbar-title>
          <v-img
            v-if="$vuetify.breakpoint.smAndUp"
            style="cursor: pointer"
            @click="routerPush('Home')"
            contain
            :max-width="124"
            :style="
              $vuetify.theme.dark ? 'filter: brightness(0) invert(1);' : ''
            "
            :src="require('@/assets/logos/madeall_full.png')"
          />
          <v-img
            v-else
            style="cursor: pointer"
            @click="routerPush('Home')"
            contain
            :max-width="32"
            :style="
              $vuetify.theme.dark ? 'filter: brightness(0) invert(1);' : ''
            "
            :src="require('@/assets/logos/madeall_icon.png')"
          />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <span v-for="item in contents" :key="item.title">
            <v-btn
              :href="item.externalLink ? item.externalLink : ''"
              target="_blank"
              @click="item.externalLink ? '' : routerPush(item.link)"
              class="font-weight-regular"
              text
              :ripple="false"
            >
              {{ item.title }}
            </v-btn>
          </span>
        </div>
        <v-spacer></v-spacer>
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-menu
            v-if="$store.getters['auth/user/GET_USER']"
            v-model="menu"
            :close-on-content-click="false"
            offset-y
            max-width="250"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="font-weight-regular"
                icon
                :ripple="false"
              >
                <v-avatar size="36" color="surface">
                  <v-img
                    :src="$store.getters['auth/user/GET_USER'].photoURL"
                    style="border: 2px solid #999999"
                  />
                </v-avatar>
              </v-btn>
            </template>

            <v-card v-if="$store.getters['auth/user/GET_USER']" class="surface">
              <v-list dense class="pa-0 surface">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-avatar color="grey lighten-2" size="38">
                      <v-img
                        v-if="$store.getters['auth/user/GET_USER']"
                        :src="$store.getters['auth/user/GET_USER'].photoURL"
                      />
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold body-2">
                      {{ $store.getters["auth/user/GET_USER"].displayName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-regular caption">
                      {{ $store.getters["auth/user/GET_USER"].email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <div
                  v-if="
                    $store.getters['auth/user/GET_CLAIMS'] &&
                    $store.getters['auth/user/GET_CLAIMS'].level < 3
                  "
                >
                  <v-list-item @click="routerPush('Admin-Orders')">
                    <v-list-item-avatar>
                      <feather size="18" type="settings" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold body-2">
                        관리자페이지
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <v-list-item @click="routerPush('MyPage')">
                  <v-list-item-avatar>
                    <feather size="18" type="user" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold body-2">
                      마이페이지
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                  @click="$store.dispatch('auth/user/SET_LOGOUT', 'Home')"
                >
                  <v-list-item-avatar>
                    <feather size="18" type="log-out" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold body-2">
                      로그아웃
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <v-btn
            v-else
            class="font-weight-regular"
            text
            @click="routerPush('Login')"
            :ripple="false"
          >
            로그인
          </v-btn>
        </div>

        <v-btn v-else @click="drawer = !drawer" icon :ripple="false">
          <feather type="menu" />
        </v-btn>
      </span>
    </v-app-bar>

    <navigation-drawer
      :propDrawer="drawer"
      v-on:update:drawer="
        (v) => {
          drawer = v;
        }
      "
    />
  </div>
</template>

<script>
import NavigationDrawer from "@/components/Drawer/NavigationDrawer.vue";
export default {
  components: { NavigationDrawer },
  data: () => ({
    drawer: false,
    menu: false,
    contents: [
      { title: "메이드올 소개", link: "AboutUs" },
      { title: "시제품 제작 서비스", link: "Service" },
      { title: "제품 소개", link: "Products" },
      {
        title: "쇼핑몰",
        link: "",
        externalLink: "https://smartstore.naver.com/madeall",
      },
      { title: "메이커 교육", link: "" },
    ],
  }),
  methods: {
    routerPush(routerName, index) {
      if (routerName === "") {
        alert("서비스 준비중입니다.");
        this.selectedItem = index;
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
};
</script>
