<template>
  <div>
    <v-navigation-drawer
      v-model="propDrawer"
      fixed
      color="background"
      right
      floating
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '100%'"
      transition="fade-transition"
    >
      <v-list-item class="px-0">
        <v-list-item-title class="mx-4">
          <v-img
            style="cursor: pointer"
            @click="routerPush('Home')"
            contain
            width="148"
            :src="require('@/assets/logos/madeall_full.png')"
          >
          </v-img>
        </v-list-item-title>

        <!-- <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img> -->
        <v-list-item-avatar class="ma-5">
          <v-btn @click="$emit('update:drawer', false)" icon color="onSurface">
            <feather type="x-circle" />
          </v-btn>
        </v-list-item-avatar>
      </v-list-item>

      <v-list flat>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :append-icon="item.items ? 'mdi-chevron-down' : ''"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content
              @click="
                item.to
                  ? routerPush(item.to)
                  : item.link
                  ? routerPush(item.link, true)
                  : ''
              "
            >
              <v-list-item-title
                class="font-weight-bold"
                :class="$vuetify.breakpoint.smAndUp ? 'display-1' : 'headline'"
                v-text="item.title"
              >
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <div v-if="item.items">
            <v-list-item v-for="child in item.items" :key="child.title">
              <v-list-item-content
                @click="child.to ? routerPush(child.to) : ''"
                class="pa-4"
              >
                <span>
                  <v-list-item-title
                    class="title font-weight-bold"
                    v-text="child.title"
                  />
                </span>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-group>
      </v-list>
      <div class="ma-5" style="bottom: 20px; right: 10px; position: absolute">
        <v-btn
          v-if="!$store.getters['auth/user/GET_USER']"
          class="mx-2"
          color="onSurface"
          @click="routerPush('Login')"
          icon
        >
          <feather type="user" />
        </v-btn>

        <v-menu
          v-else
          v-model="menu"
          :close-on-content-click="false"
          top
          right
          max-width="250"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="font-weight-regular"
              icon
              :ripple="false"
            >
              <v-avatar size="50" color="surface">
                <v-img :src="$store.getters['auth/user/GET_USER'].photoURL" />
              </v-avatar>
            </v-btn>
          </template>

          <v-card v-if="$store.getters['auth/user/GET_USER']" class="surface">
            <v-list dense class="pa-0 surface">
              <v-list-item two-line>
                <v-list-item-avatar>
                  <v-avatar color="grey lighten-2" size="48">
                    <v-img
                      v-if="$store.getters['auth/user/GET_USER']"
                      :src="$store.getters['auth/user/GET_USER'].photoURL"
                    />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold body-2">
                    {{ $store.getters["auth/user/GET_USER"].displayName }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="font-weight-regular caption">
                    {{ $store.getters["auth/user/GET_USER"].email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <div
                v-if="
                  $store.getters['auth/user/GET_CLAIMS'] &&
                  $store.getters['auth/user/GET_CLAIMS'].level < 3
                "
              >
                <v-divider></v-divider>
                <v-list-item @click="routerPush('Admin-Orders')">
                  <v-list-item-avatar>
                    <feather size="18" type="settings" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold body-2">
                      관리자페이지
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-list-item @click="routerPush('MyPage')">
                <v-list-item-avatar>
                  <feather size="18" type="user" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold body-2">
                    마이페이지
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="$store.dispatch('auth/user/SET_LOGOUT', 'Home')">
                <v-list-item-avatar>
                  <feather size="18" type="log-out" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold body-2">
                    로그아웃
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    propDrawer: Boolean,
  },
  components: {},

  mounted() {},
  methods: {
    routerPush(routerName, external) {
      if (external) {
        location.href = routerName;
      }
      if (routerName === "") {
        alert("서비스 준비중입니다.");
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
  data() {
    return {
      menu: false,
      items: [
        {
          title: "메이드올 소개",
          to: "AboutUs",
        },
        {
          active: false,
          to: "Order",
          // items: [{ title: "child title", to: "" }],
          title: "시제품 제작 서비스",
        },
        {
          title: "제품 소개",
          to: "Products",
        },
        {
          title: "쇼핑몰",
          link: "https://smartstore.naver.com/madeall",
        },
        {
          title: "메이커 교육",
          to: "",
        },
      ],
    };
  },
};
</script>

<style></style>
