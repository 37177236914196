<template>
  <v-container fill-height class="pa-0 transparent">
    <page-appbar />
    <v-row class="ma-0">
      <v-col cols="12" class="pa-auto ma-auto">
        <v-card
          max-width="360"
          class="pa-0 mx-auto"
          rounded="xl"
          color="transparent"
          flat
        >
          <v-card flat class="transparent">
            <div
              :class="
                $vuetify.breakpoint.smAndDown || !type
                  ? 'text-center'
                  : 'text-center'
              "
              class="pa-0"
            >
              <span
                class="font-weight-bold headline"
                v-html="type == 1 ? '로그인' : '비밀번호 찾기'"
              >
              </span>
            </div>
          </v-card>
          <sign-in v-if="type == 1" @changeType="changeType" />
          <reset-password v-if="type == 2" @changeType="changeType" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style></style>
<script>
if (!window.Kakao.isInitialized()) {
  window.Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY_WEB);
  window.Kakao.isInitialized();
}

import SignIn from "@/components/Authorization/signIn";
import setMeta from "@/utils/setMeta";
import PageAppbar from "../../components/Appbar/PageAppbar.vue";
import ResetPassword from "../../components/Authorization/resetPassword.vue";

export default {
  components: {
    SignIn,
    PageAppbar,
    ResetPassword,
  },
  data() {
    return {
      type: 1,
    };
  },
  methods: {
    changeType(index) {
      this.type = index;
    },
  },

  created() {
    setMeta({
      title: "로그인",
      description: "메이드올의 온라인 메이커 플랫폼입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
    // if (this.$store.getters["auth/user/GET_USER"]) {
    //   this.type = 2;
    // }
  },
};
</script>
